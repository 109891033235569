.insure {
  width: 1200px;
  margin: 0 auto;
  background-color: #ecf0f5;
  padding: 20px 25px;
  box-sizing: border-box;
}

.insure .insuretitle {
  margin: 0;
  font-size: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.insure .insuretitle .back {
  font-size: 18px;
  color: rgb(95, 95, 95);
  cursor: pointer;
}

.insure .productselection {
  background-color: #fff;
  padding: 15px 20px;
  box-sizing: border-box;
  margin: 15px 0;
}

.insure .productselection .title {
  font-size: 18px;
  margin: 0;
  line-height: 1;
  height: 35px;
  border-bottom: 1px solid #f4f4f4;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.insure .productselection .title img {
  margin-top: 8px;
}

.insure .productselection .lx {
  margin: 10px 0;
}

.insure .productselection .lx .bx {
  color: #dd4b39 !important;
  font-weight: normal;
  margin-left: 15px;
}

.insure .productselection .lx .img {
  cursor: pointer;
  padding: 0 5px;
}

.insure .productselection .lxtitle {
  display: flex;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
  align-items: center;
}

.insure .productselection .radiobtn {
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid #d2d6de;
  padding: 10px;
}

.insure .productselection .radiobtn2 {
  padding: 10px;
}

.insure .sminfo {
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid #d2d6de;
  padding: 10px 20px;
  font-weight: 700;
  letter-spacing: 1.5px;
  line-height: 28px;
  box-sizing: border-box;
}

.insure .sminfo .blue {
  color: #3c8dbc;
  cursor: pointer;
}

.insure .bxinfo {
  background-color: #fff;
  padding: 15px 20px;
  box-sizing: border-box;
  margin: 15px 0;
}

.insure .bxinfo .title {
  text-align: center;
  font-size: 18px;
  margin: 0;
  line-height: 1;
  height: 35px;
  border-bottom: 1px solid #f4f4f4;
  cursor: pointer;
}

.insure .bxinfo .title img {
  float: right;
  margin-top: 8px;
}

.insure .bxinfo .input {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.insure .bxinfo .input .bxinput {
  width: 48%;
  margin: 10px 0;
}

.insure .bxinfo .input2 .bxinput {
  width: 48%;
  margin: 10px 0;
}

.insure .bxinfo .input .top {
  display: flex;
  justify-content: space-between;
  margin: 0 0 10px 0;
  align-items: center;
}

.insure .bxinfo .input .bxname {
  font-weight: 700;
}

.insure .bxinfo .input .bt {
  color: #dd4b39;
  font-size: 14px;
}

.insure .bxinfo .bxinput .bottom2 {
  display: flex;
  justify-content: space-between;
}

.insure .bxinfo .ant-upload-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.insure .tjbtn {
  background-color: #fff;
  padding: 15px 20px;
  box-sizing: border-box;
  margin: 15px 0;
  display: flex;
  justify-content: center;
}

.insure .tjbtn button {
  margin: 0 15px;
}

.insure canvas {
  width: 100px;
}
