.freightinsurance {
  width: 100%;
  height: 360px;
  background: #f7f7f7;
  background: url(../images/zxfw/zzbg.png);
  background-position: center;
  background-size: cover;
}

.freightitem {
  width: 100%;
  height: 360px;
}

.freightitem .itemcon {
  width: 1200px;
  margin: 0 auto;
  margin-top: -60px;
  display: flex;
  justify-content: space-between;
}

.freightitem .item {
  width: 380px;
  height: 300px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.freightitem .item:hover {
  box-shadow: 0px 1px 20px 0px rgba(96, 96, 96, 0.14);
  cursor: pointer;
}

.freightitem .item div {
  margin: 10px;
}

.freightitem .item .topimg {
  /* background-color: red; */
  width: 135px;
}

.freightitem .item .title {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 33px;
}
.freightitem .item .con {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 33px;
}

.advertising {
  width: 100%;
  height: 360px;
  background: #f7f7f7;
  background: url(../images/zxfw/zzggbg.png);
  background-position: center;
  background-size: cover;
}

.advertisingitem {
  width: 100%;
  height: 360px;
}

.advertisingitem .itemcon {
  width: 1200px;
  margin: 0 auto;
  margin-top: -60px;
  display: flex;
  justify-content: space-between;
}

.advertisingitem .item {
  width: 580px;
  height: 300px;
  background: #ffffff;

  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.advertisingitem .item:hover {
  box-shadow: 0px 1px 20px 0px rgba(96, 96, 96, 0.14);
}

.advertisingitem .item div {
  margin: 10px;
}

.advertisingitem .item .topimg {
  /* background-color: red; */
  width: 135px;
}

.advertisingitem .item .title {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 33px;
}
.advertisingitem .item .con {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 33px;
}

.insure{
  width: 100%;
  height: auto;
  /* background-color: red; */
}

@media only screen and (max-width: 1200px) {
  .advertising {
    width: 1200px;
  }

  .freightinsurance {
    width: 1200px;
  }
}
