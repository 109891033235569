.aboutus{
    width: 100%;
}

.aboutus .topimg{
    width: 100%;
    height: 290px;
    background: #F7F7F7;
    background: url(../images/aboutus/aboutusbg.png);
    background-position:center;
    background-size:cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.companyProfile{
    width: 100%;
}

.companyProfile .companyProfilecon{
    width: 1200px;
    margin: 0 auto;
    text-align: center;
}

.companyProfilecon .title{
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 33px;
    margin: 50px 0;
}

.profilecon {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profilecon .leftcon{
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #222222;
    line-height: 24px;
    margin-right: 120px;
    text-align: left;
}

.lnitem{
    width: 100%;
    height: 224px;
    background: #F5F8FB;
    margin: 50px 0;
}

.lnitem .lnitemcon{
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.lnitem .lnitemcon .item{
    width: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lnitem .lnitemcon .item .leftimg{
    margin-right: 20px;
}
.lnitem .rightcon{
    width: 200px;
}
.lnitem .rightcon .title{
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #222222;
    line-height: 24px;
    margin-bottom: 5px;
}

.lnitem .rightcon .con{
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #222222;
    /* line-height: 24px; */
}

.developmenthistory{
    width: 100%;

}

.developmenthistory .developmenthistorycon{
    width: 1200px;
    margin: 0 auto;
}

.developmenthistorycon .title{
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 33px;
    margin: 50px 0;
    text-align: center;
}

.developmenthistorycon .history{
    position: relative;
}

.developmenthistorycon .history .item{
    position: absolute;
    bottom: 0;
    width: 135px;
    height: auto;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
}

.developmenthistorycon .history .item .title{
    font-size: 16px;
    margin-bottom: 0;
}

.developmenthistorycon .history .item1{
    left: 0;
    bottom: 20px;
}

.developmenthistorycon .history .item2{
    left: 190px;
    bottom: 90px;
}

.developmenthistorycon .history .item3{
    left: 410px;
    bottom: 60px;
}

.developmenthistorycon .history .item4{
    left: 640px;
    bottom: 135px;
}

.developmenthistorycon .history .item5{
    left: 847px;
    bottom: 130px;
}

.developmenthistorycon .history .item6{
    right: 0;
    bottom: 168px;
}


.address{
    width: 100%;
    /* margin: 50px 0; */
    height: 667px;
    background: #F9FBFC;
    border-top: 1px solid transparent;
}

.address .developmenthistorycon{
    width: 1200px;
    margin: 0 auto;
}

.address .title{
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 33px;
    margin: 50px 0;
    text-align: center;
    margin: 50px 0;
}
.addressmap{
    width: 1200px;
    margin: 0 auto;
}
.maptitle{
    width: 80px;
    text-align: center;
    font-weight: 700;
    margin-left: -40px;
}

.info{
    width: 1200px;
    margin: 30px auto;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 34px;
}

@media only screen and (max-width: 1200px) {
    
    .aboutus .topimg{
        width: 1200px;

    }
   
}