/* footer */

.footer {
  width: 100%;
  background: #363b46;
  text-align: center;
  height: 180px;
}

.footer .footercon {
  width: 1200px;
  height: 180px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #6e7380;
}
.footer .footercon .rightinfo {
  width: 820px;
  /* display: flex;
    justify-content: space-between;
    align-items: center; */
}
.footer .footercon .rightinfo .toptitle {
  border-bottom: 1px solid #595e6b;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 820px;
}

.footer .footercon .rightinfo a {
  color: #6e7380;
  text-decoration: none;
}

.footer .footercon .rightinfo .item {
  flex: 1;
  text-align: left;
  height: 40px;
  cursor: pointer;
}

.footer .footercon .rightinfo .con {
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 820px;
  margin-top: 10px;
}

.footer .footercon .rightinfo .con .item div {
  margin-bottom: 5px;
  cursor: pointer;
}

.footer .footercon .rightinfo .con .item div:hover {
  color: rgb(243, 241, 240);
}
.footer .footercon .rightinfo .con .item div:hover a{
  color: rgb(241, 238, 237);
}

.footerend {
  height: 48px;
  background: #31353d;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footerend .footerendcon {
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #9e9e9e;
  line-height: 33px;
}

@media only screen and (max-width: 1200px) {
  .showitem {
    width: 95%;
    margin: 0 auto;
  }
  .applyuse {
    width: 1200px;
  }
  .footer {
    width: 1200px;
  }
  .footerend {
    width: 1200px;
  }
}
