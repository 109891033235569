.home {
  height: 100vh;
  width: 100%;
  background-color: rgb(155, 153, 153);
}
.toptabzw {
  width: 100%;
  height: 80px;
}
.toptab {
  background-color: #fff;
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  z-index: 999;
}

.toptab .tabcon {
  width: 1200px;
  background-color: #fff;
  height: 80px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tab {
  display: flex;
  justify-content: end;
}
.tabitem {
  display: flex;
  justify-content: end;
}
.tabitem {
  font-size: 18px;
  font-weight: 400;
  color: #333;
  line-height: 33px;
}
.tabitem .item {
  margin-right: 30px;
  padding-bottom: 5px;
  cursor: pointer;
}

.tabitem .item .bottomicon {
  margin-left: 0px;
}

.tabitem .item a {
  color: #333;
  padding-bottom: 5px;
  cursor: pointer;
  text-decoration: none;
  /* background-color: aqua; */
}

.toptab .tabitem .active a {
  text-decoration: none;
  color: #0064b1;
  border-bottom: 2px solid #0064b1;
}

.dlbtn .item {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0064b1;
  line-height: 33px;
  border: 1px solid #0064b1;
  border-radius: 6px;
  padding: 0px 15px;
  height: 36px;
  padding-bottom: 0px !important;
}

.tabitem .itemend {
  margin-right: 0px;
  background-color: #0064b1;
  color: #fff;
}

.syalert {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 90vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.dialog-box {
  position: absolute;
  background-color: #fff;
  width: 350px;
  height: 400px;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  padding: 20px 30px;
  box-sizing: border-box;
}

.syalert .close {
  position: absolute;
  right: 8px;
  top: 10px;
  /* background-color: red; */
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 20px;
  color: rgb(111, 111, 111);
  cursor: pointer;
}

.syalert input {
  width: 100%;
  margin: 20px auto;
  height: 42px;
  appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #bfcbd9;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f2d3d;
  font-size: inherit;
  height: 36px;
  line-height: 1;
  outline: 0;
  padding: 3px 10px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.syalert .btn {
  width: 100%;
  /* background-color: red; */
  display: flex;
  justify-content: center;
}

.syalert button {
  color: #fff;
  background-color: #20a0ff;
  border: 1px solid #20a0ff;
  font-size: 18px;
  display: inline-block;
  line-height: 1;
  cursor: pointer;
  width: 150px;
  height: 38px;
  border-radius: 8px;
}

@media only screen and (max-width: 1200px) {
  .toptab .tabcon {
    width: 100%;
  }
}
