.production{
    width: 100%;
    height: 360px;
    background: #F7F7F7;
    background: url(../images/production/probg.png);
    background-position:center;
    background-size:cover;
    position: relative;
}

.production .productioncon{
    width: 1200px;
    height: 360px;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.production .productioncon .centercon{
    font-size: 18px;
    font-weight: 400;
    color: #F7F7F7;
    margin: 20px 0;
}

.introduce{
    width: 100%;
    height: 1080px;
    background: #F7F7F7;
}

.introduce .introducecon{
    width: 1200px;
    margin: 0 auto;
    border-top: 1px solid transparent;
}

.introduce .introducecon .first{
    background: #FFFFFF;
    box-shadow: 0px 1px 20px 0px rgba(96,96,96,0.14);
    border-radius: 10px;
    display: flex;
    margin: 40px 0;
}

.introduce .introducecon .first .introduceimg{
    flex: 1;
}

.introduce .introducecon .first .introducetext{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.introduce .introducecon .first .introducetext .text{
    margin: 0 70px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    /* color: #cf2f2f; */
    line-height: 42px;
}

@media only screen and (max-width: 1200px) {
    
    .production{
        width: 1200px;
    }
   
}

