.logistics{
    background-color: #F7F7F7;
    border: 1px solid transparent;
}

.logistics .echars{
    border-top: 1px solid transparent;
    width: 1160px;
    height: 400px;
    background: #FFFFFF;
    border-radius: 6px;
    margin: 40px auto;
    padding: 20px;
    /* box-sizing: border-box; */
}

.article{
    width: 1200px;
    margin: 40px auto;
    padding: 20px;
    box-sizing: border-box;
    background: #FFFFFF;
}

.article .title{
    font-size: 20px;
    font-family: Alibaba PuHuiTi;
    font-weight: 500;
    color: #333333;
    line-height: 25px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.article .title .more{
    font-size: 14px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    color: #037CD8;
    line-height: 25px;
}

.article .articlecon{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.article .rightitems{
    width: 100%;
    margin-left: 50px;

}

.article .rightitems .items{
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    color: #333333;
    line-height: 42px;
    /* background: #F0F7FD; */
}

.article .rightitems .items:hover{
    background: #F0F7FD;
    cursor: pointer;
}





